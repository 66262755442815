export const firebaseConfig = {
  apiKey: 'AIzaSyBNgVkDhaaPjdTJJq6LsV5eYIpPQZWn450',
  authDomain: 'siteline-staging.firebaseapp.com',
  databaseURL: 'https://siteline-staging.firebaseio.com',
  projectId: 'siteline-staging',
  storageBucket: 'siteline-staging.appspot.com',
  messagingSenderId: '818666290880',
  appId: '1:818666290880:web:411156d4abcbe3ed093725',
  measurementId: 'G-X2BHVXCZM0',
}

export const apiBaseUrl = 'staging.api-admin.siteline.com'
export const subscriptionsBaseUrl = 'staging.subscriptions-admin.siteline.com'
export const pdfBaseUrl = 'https://staging.pdf.siteline.com'
export const reportingBaseUrl = 'https://staging.reporting.siteline.com'
export const googleMapsApiKey = 'AIzaSyBNgVkDhaaPjdTJJq6LsV5eYIpPQZWn450'
export const logRocketApiUrl = 'https://staging.4c05439.siteline.com/i'
export const sentryTunnel = 'https://staging.759ed53.siteline.com/api/5375023/envelope/'
