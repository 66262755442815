import { gql } from '@apollo/client'
import {
  Autocomplete,
  Container,
  Grid2,
  Grow,
  Switch,
  TextField,
  Theme,
  Typography,
} from '@mui/material'
import _ from 'lodash'
import moment from 'moment-timezone'
import { useMemo, useState } from 'react'
import { CartesianGrid, Label, Line, LineChart, ResponsiveContainer, XAxis, YAxis } from 'recharts'
import { safeDivide, sortPayAppsByBillingEnd } from 'siteline-common-all'
import { SitelineText, colors, makeStylesFast } from 'siteline-common-web'
import Page from '../../common/components/Page'
import { useFullyBilledContractsQuery } from '../../common/graphql/apollo-operations'

gql`
  query fullyBilledContracts {
    fullyBilledContracts {
      id
      timeZone
      pastPayAppCount
      percentComplete
      project {
        id
        name
      }
      company {
        id
        name
      }
      payApps {
        id
        billingEnd
        balanceToFinish
        currentBilled
        payAppNumber
        retentionOnly
      }
      sov {
        id
        totalValue
        totalRetention
      }
    }
  }
`

const useStyles = makeStylesFast((theme: Theme) => ({
  root: {
    padding: theme.spacing(3, 0),
    '& .chartContainer': {
      marginTop: theme.spacing(4),
      width: '100%',
      height: '100%',
      minHeight: 500,
    },
  },
  subheader: {
    display: 'block',
    margin: theme.spacing(1, 0, 3),
    width: '70%',
  },
  sidebar: {
    borderRadius: theme.spacing(0.5),
    backgroundColor: colors.grey20,
    margin: theme.spacing(4, 0, 0, 2),
    padding: theme.spacing(2, 3),
    display: 'flex',
    flexDirection: 'column',
    '& > *': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
}))

/** Queries historical billing across projects in Siteline and charts billing curves */
export default function BillingHistory() {
  const classes = useStyles()
  const { data } = useFullyBilledContractsQuery()

  const [chartType, setChartType] = useState<'linear' | 'cumulative'>('cumulative')
  const [companyId, setCompanyId] = useState<string | null>(null)
  const [viewingContractId, setViewingContractId] = useState<string | null>(null)

  const contracts = useMemo(() => {
    if (!data) {
      return []
    }
    return (
      data.fullyBilledContracts
        // Filter out Siteline company
        .filter((contract) => contract.company.id !== '7fc204ef-93c2-42b1-81dc-2083bd18ab1b')
        // Filter out contracts with pre-Siteline billing
        .filter((contract) => contract.pastPayAppCount === 0)
    )
  }, [data])

  // Derive companies from the contract list, and remove companies that haven't used Siteline
  // in a long time
  const companies = useMemo(() => {
    const allCompanies = _.chain(contracts)
      .map(({ company }) => company)
      .uniqBy((company) => company.id)
      .orderBy((company) => company.name, 'asc')
      .value()
    return allCompanies.filter((company) => {
      const companyContracts = contracts.filter((contract) => contract.company.id === company.id)
      const contractDates = _.chain(companyContracts)
        .map((contract) =>
          _.max(contract.payApps.map((payApp) => moment.tz(payApp.billingEnd, contract.timeZone)))
        )
        .compact()
        .value()
      const latestContractDate = _.max(contractDates)
      // Include only companies with billing in the last 9 months
      return moment.utc().diff(latestContractDate, 'months') <= 9
    })
  }, [contracts])
  const companyIds = useMemo(() => new Set(companies.map((company) => company.id)), [companies])

  const filteredContracts = useMemo(
    () => contracts.filter((contract) => companyIds.has(contract.company.id)),
    [companyIds, contracts]
  )

  const billingByMonth = useMemo(() => {
    const billingData = filteredContracts
      .filter((contract) => !companyId || contract.company.id === companyId)
      .map((contract) => {
        const { timeZone } = contract
        const totalValue = contract.sov?.totalValue ?? 0
        const sortedPayApps = sortPayAppsByBillingEnd([...contract.payApps], 'asc', timeZone)
        const lastBillingPayAppIndex = _.findIndex(
          sortedPayApps,
          (payApp) => payApp.balanceToFinish === 0
        )
        const payApps =
          lastBillingPayAppIndex !== -1 ? _.take(sortedPayApps, lastBillingPayAppIndex + 1) : []
        const progressPayApps = payApps.filter((payApp) => !payApp.retentionOnly)
        if (progressPayApps.length === 0) {
          return null
        }
        const firstMonth = moment.tz(progressPayApps[0].billingEnd, timeZone)
        const lastMonth = moment.tz(
          progressPayApps[progressPayApps.length - 1].billingEnd,
          timeZone
        )
        const totalMonths = lastMonth.diff(firstMonth, 'months') + contract.pastPayAppCount
        const months = progressPayApps.map((payApp) => {
          const month =
            moment.tz(payApp.billingEnd, timeZone).diff(firstMonth, 'months') +
            // Add the number of pre-Siteline pay apps on the contract
            contract.pastPayAppCount
          const normalizedMonth = safeDivide(month, totalMonths, 0)
          const amount = payApp.currentBilled
          const cumulativeAmount = _.subtract(totalValue, payApp.balanceToFinish)
          return {
            month,
            normalizedMonth,
            percent: safeDivide(amount, totalValue, 0),
            cumulativePercent: safeDivide(cumulativeAmount, totalValue, 0),
          }
        })
        return {
          id: contract.id,
          company: contract.company,
          project: contract.project.name,
          months,
        }
      })
    return _.compact(billingData)
  }, [companyId, filteredContracts])

  const ticks = useMemo(() => _.range(-1, 13, 1), [])
  const lineChartData = useMemo(() => {
    return ticks.map((num) => {
      const month = num / 12
      const contractAmounts = _.chain(billingByMonth)
        .map((contract) => {
          // Add a point for this contract representing where the contract billing was at this point
          const lastInRange = _.findLast(
            contract.months,
            ({ normalizedMonth }) => normalizedMonth <= month
          )
          let amount =
            chartType === 'cumulative' ? lastInRange?.cumulativePercent : lastInRange?.percent
          if (!amount) {
            amount = 0
          }
          return { contractId: contract.id, amount }
        })
        .compact()
        .value()
      const lineData: Record<string, unknown> = { month: month * 12 }
      contractAmounts.forEach(({ contractId, amount }) => {
        lineData[contractId] = amount
      })
      return lineData
    })
  }, [billingByMonth, chartType, ticks])

  const viewingContract = useMemo(
    () => contracts.find((contract) => contract.id === viewingContractId) ?? null,
    [contracts, viewingContractId]
  )

  return (
    <Page className={classes.root} title="Billing History">
      <Container maxWidth={false}>
        <Typography variant="h4">Billing History</Typography>
        <SitelineText variant="body2" color="grey50" className={classes.subheader}>
          Billing visualization for projects fully billed, from start to finish, in Siteline. Shows
          percent billed by month, normalized over a 12-month period. Hover over a curve to see the
          name of the project in the sidebar, and click to open the contract detail page in admin.
        </SitelineText>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Autocomplete
            size="small"
            disablePortal
            options={companies.map((company) => company.id)}
            value={companyId}
            onChange={(event, value) => setCompanyId(value)}
            sx={{ width: 250 }}
            getOptionLabel={(companyId) => {
              const company = companies.find((company) => company.id === companyId)
              return company?.name ?? ''
            }}
            renderInput={(params) => <TextField {...params} label="Company" />}
            style={{ marginRight: 32 }}
          />
          <Switch
            checked={chartType === 'cumulative'}
            onChange={(ev) => setChartType(ev.target.checked ? 'cumulative' : 'linear')}
          />
          <SitelineText variant="body1">Cumulative</SitelineText>
        </div>
        <Grid2 container>
          <Grid2 size={{ xs: 8 }} className="chartContainer">
            <ResponsiveContainer height={500} width="100%">
              <LineChart data={lineChartData} margin={{ bottom: 32, left: 32 }}>
                <CartesianGrid />
                <XAxis
                  dataKey="month"
                  ticks={ticks}
                  type="number"
                  allowDecimals={false}
                  domain={['dataMin', 'dataMax']}
                >
                  <Label value="Month (normalized)" offset={8} position="bottom" />
                </XAxis>
                <YAxis>
                  <Label
                    angle={-90}
                    value="Percent billed"
                    offset={8}
                    position="left"
                    orientation="vertical"
                  />
                </YAxis>
                {billingByMonth.map(({ id }) => (
                  <Line
                    key={id}
                    type="basis"
                    dataKey={id}
                    dot={false}
                    strokeWidth={3}
                    stroke={viewingContractId === id ? colors.blue50 : 'rgba(0, 0, 0, 0.2)'}
                    onMouseEnter={() => setViewingContractId(id)}
                    onMouseLeave={() => setViewingContractId(null)}
                    onClick={() => window.open(`/contracts/${id}`, '_blank')}
                  />
                ))}
              </LineChart>
            </ResponsiveContainer>
          </Grid2>
          <Grid2 size={{ xs: 3 }}>
            <Grow in={viewingContract !== null}>
              <div className={classes.sidebar}>
                <SitelineText variant="body1" bold>
                  {viewingContract?.project.name ?? ''}
                </SitelineText>
                <SitelineText variant="body2">{viewingContract?.company.name ?? ''}</SitelineText>
              </div>
            </Grow>
          </Grid2>
        </Grid2>
      </Container>
    </Page>
  )
}
