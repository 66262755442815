import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight'
import { Button, IconButton, TableBody, TableCell, TableRow, Theme } from '@mui/material'
import _ from 'lodash'
import { useState } from 'react'
import { FormTemplateType } from 'siteline-common-all'
import { makeStylesFast } from 'siteline-common-web'
import { v4 as uuidv4 } from 'uuid'
import { IdentifierIconButton } from '../../common/components/IdentifierRow'
import { PayAppRequirementGroupWithKey, PayAppRequirementWithKey } from './PayAppRequirementGroups'
import {
  PayAppRequirementEditableRow,
  PayAppRequirementUneditableRow,
} from './PayAppRequirementRow'

const useStyles = makeStylesFast((theme: Theme) => ({
  elseIf: {
    display: 'flex',
    alignItems: 'center',
    '& .MuiSvgIcon-root': {
      paddingRight: theme.spacing(0.5),
    },
  },
}))

interface PayAppRequirementGroupProps {
  payAppRequirementGroup: PayAppRequirementGroupWithKey
  setPayAppRequirementGroup: (group: PayAppRequirementGroupWithKey) => void
  removePayAppRequirementGroup: () => void
  isEditing: boolean
  templateType: FormTemplateType
  groupIndex: number
  usesManualStoredMaterials: boolean
}

export function PayAppRequirementGroup({
  payAppRequirementGroup,
  setPayAppRequirementGroup,
  removePayAppRequirementGroup,
  isEditing,
  templateType,
  groupIndex,
  usesManualStoredMaterials,
}: PayAppRequirementGroupProps) {
  const classes = useStyles()

  const payAppRequirements = _.sortBy(
    payAppRequirementGroup.requirements,
    (requirement) => requirement.groupOrder
  )

  const hasFallback = payAppRequirements.some((requirement) => requirement.conditions.length === 0)
  const [noFallback, setNoFallback] = useState(!hasFallback)

  const setPayAppRequirements = (newRequirements: PayAppRequirementWithKey[]) => {
    setPayAppRequirementGroup({
      ...payAppRequirementGroup,
      requirements: newRequirements,
    })
  }

  const setOrder = (newOrder: number) => {
    setPayAppRequirementGroup({
      ...payAppRequirementGroup,
      order: newOrder,
    })
  }

  const addRequirementAfter = (after: PayAppRequirementWithKey) => {
    const newRequirement = {
      templateVariantId: null,
      groupOrder: 1,
      conditions: [],
      key: uuidv4(),
    }
    const index = _.findIndex(payAppRequirements, (req) => req.key === after.key)
    if (index === -1) {
      throw new Error('Could not find requirement to insert new row after')
    }

    const newRequirements = [...payAppRequirements]
    newRequirements.splice(index + 1, 0, newRequirement)
    const withOrder = newRequirements.map((requirement, index) => ({
      ...requirement,
      groupOrder: index + 1,
    }))

    setPayAppRequirements(withOrder)
  }

  const updateRequirement = (requirement: PayAppRequirementWithKey) => {
    const index = _.findIndex(payAppRequirements, (req) => req.key === requirement.key)
    if (index === -1) {
      throw new Error('Could not find requirement to update')
    }
    const newRequirements = [...payAppRequirements]
    newRequirements[index] = requirement
    setPayAppRequirements(newRequirements)
  }

  const removeRequirement = (requirement: PayAppRequirementWithKey) => {
    const requirements = _.without(payAppRequirements, requirement)

    // If we have requirements still, just update the requirements array
    if (requirements.length > 0) {
      setPayAppRequirements(requirements)

      // If we no longer have requirements, remove the group altogether
    } else {
      removePayAppRequirementGroup()
    }
  }

  const onAddFallback = () => {
    const lastRequirement = _.last(payAppRequirements)
    if (!lastRequirement) {
      throw new Error('Could not find last requirement')
    }
    setNoFallback(false)
    addRequirementAfter(lastRequirement)
  }

  return (
    <TableBody>
      {payAppRequirements.length === 0 && (
        <TableRow>
          <TableCell>Group {groupIndex + 1}</TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell>
            {isEditing && (
              <IconButton onClick={() => removePayAppRequirementGroup()} size="small">
                <DeleteIcon />
              </IconButton>
            )}
            {!isEditing && <IdentifierIconButton id={payAppRequirementGroup.id ?? ''} />}
          </TableCell>
        </TableRow>
      )}
      {!isEditing &&
        payAppRequirements.map((requirement, index) => (
          <PayAppRequirementUneditableRow
            key={requirement.key}
            requirement={requirement}
            isFirstRow={index === 0}
            isLastRow={index === payAppRequirements.length - 1}
            order={payAppRequirementGroup.order}
            noFallback={noFallback}
            groupIndex={groupIndex}
            usesManualStoredMaterials={usesManualStoredMaterials}
          />
        ))}
      {isEditing && (
        <>
          {payAppRequirements.map((requirement, index) => (
            <PayAppRequirementEditableRow
              key={requirement.key}
              payAppRequirement={requirement}
              setPayAppRequirement={(updatedRequirement) => updateRequirement(updatedRequirement)}
              isFirstRow={index === 0}
              isLastRow={index === payAppRequirements.length - 1 && index !== 0}
              templateType={templateType}
              order={payAppRequirementGroup.order}
              setOrder={setOrder}
              noFallback={noFallback}
              setNoFallback={setNoFallback}
              addRequirement={() => addRequirementAfter(requirement)}
              removeRequirement={() => removeRequirement(requirement)}
              groupIndex={groupIndex}
              usesManualStoredMaterials={usesManualStoredMaterials}
            />
          ))}
          {noFallback && (
            <TableRow>
              <TableCell></TableCell>
              <TableCell className={classes.elseIf}>
                <SubdirectoryArrowRightIcon />
                No fallback
              </TableCell>
              <TableCell>
                <Button onClick={onAddFallback} startIcon={<AddIcon />}>
                  Add fallback
                </Button>
              </TableCell>
            </TableRow>
          )}
        </>
      )}
    </TableBody>
  )
}
